.chromeframe { margin: 0.2em 0; background: #ccc; color: black; padding: 0.2em 0; }

.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; *line-height: 0; }
.ir br { display: none; }
.hidden { display: none !important; visibility: hidden; }
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }

.bi{ display: block;}

.lt-ie7 .clearfix{
  height: 1%;
  overflow: visible;
}
.lt-ie8 .clearfix{
  min-height: 1%;
}
.clearfix {
    zoom: 1;
    &:before,
    &:after {
        display: table;
        content: "";
        zoom: 1;
    }
    &:after {
        clear: both;
    }
}

.pull-left{float:left;}
.pull-right{float:right;}

ul,ol{
    &.unstyled{
        list-style: none;
        margin: 0;
        padding:  0;
    }
}

.centered { text-align: center; }
.strike { text-decoration: line-through; }
