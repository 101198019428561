//
// mixin for bullet proof font declaration syntax
//
@mixin declare-font-face($font-family, $font-filename, $font-weight : normal, $font-style :normal, $font-stretch : normal){
    @font-face{
        font-family: '#{$font-family}';
        src: url(#{$font-filename}.woff2) format('woff2'),
             url(#{$font-filename}.woff) format('woff');
        font-weight: $font-weight;
        font-style: $font-style;
        font-stretch: $font-stretch;
    }
}
@include declare-font-face('edwardpro', 'fonts/EdwardPro-Normal', 400);

$basecolor: #fff;
$selection: #241a17;
$linkcolor: #fbca00;
$orange: #FF5800;

$blacktext: #000;
