body {
    @include font-edwardpro(400, 18px, normal);
    color: #3e3e3e;
    background-color: #1B1B1B;
    position: relative;
    &.home {
        @include size(100vw, 100vh);
        .lonsdale-logo {
            @include size(190px, auto);
            @include absolute-center();
        }
    }
    &.single {
        &.ios {
            .lonsdale-logo {
                max-width: 300px;
                margin: 50px auto 0;
                display: block;
                @media (min-width: $screen-sm-min) {
                }
                @media (min-width: $screen-md-min) {
                    max-width: 190px;
                }
                @media (min-width: $screen-lg-min) {
                }
            }
        }
    }
}

h1 {
    font-size: 32px;
    color: #FFF;
    margin: 40px 0 30px;
    text-align: center;
    @media (min-width: $screen-sm-min) {
    }
    @media (min-width: $screen-md-min) {
        font-size: 64px;
    }
    @media (min-width: $screen-lg-min) {
    }
}

.main {
    @include size(100%, auto);
    background-color: #FFF;
    margin: 0 0 25px;
    padding: 0 0 25px;
    @media (min-width: $screen-sm-min) {
    }
    @media (min-width: $screen-md-min) {
        margin: 0;
        padding: 0 0 50px;
    }
    @media (min-width: $screen-lg-min) {
    }
}

a {
    color: $orange;
    text-decoration: none;
    &.download {
        @include size(160px, auto);
        font-weight: 700;
        font-size: 14px;
        color: white;
        margin: 0 auto;
        padding: 15px 20px;
        display: block;
        background-color: $orange;
        text-align: center;
        text-transform: uppercase;
    }
}

.side-menu {
    padding: 25px 0 0;
    @media (min-width: $screen-sm-min) {
    }
    @media (min-width: $screen-md-min) {
        padding: 50px 0 0;
    }
    @media (min-width: $screen-lg-min) {
    }
    .app-icon {
        @include size(140px, auto);
        margin: 0 auto 50px;
        display: block;
        border-radius: 20px;
        @media (min-width: $screen-sm-min) {
        }
        @media (min-width: $screen-md-min) {
            margin: 0 auto 100px;
        }
        @media (min-width: $screen-lg-min) {
        }
    }
    .downloads-list {
        li {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}

.instructions {
    padding: 50px 20px 0;
    @media (min-width: $screen-sm-min) {
    }
    @media (min-width: $screen-md-min) {
        padding: 50px 0 0;
    }
    @media (min-width: $screen-lg-min) {
    }
    h2  {
        font-weight: 700;
        line-height: 30px;
        margin: 0 0 30px;
    }
    p {
        margin: 0 0 20px;
    }
    a {
        margin: 0 0 20px;
        display: inline-block
    }
}





